'use strict';

/**ハンバーガーボタンの処理 */
// 型定義
type ButtonElement = HTMLButtonElement | null;
type NavElement = HTMLElement | null;

// 要素の取得
const button: ButtonElement = document.querySelector(
  'button.l-headerNavigationButton[data-js="true"]'
);
const nav: NavElement = document.querySelector('.l-headerNav[data-js="true"]');

// クリックイベントの処理関数
function toggleNavigation() {
  if (button && nav) {
    button.classList.toggle('on');
    nav.classList.toggle('on');
  }
}

// イベントリスナーの追加
if (button) {
  button.addEventListener('click', toggleNavigation);
}
