'use strict';

// ターゲット要素とヘッダー要素の型を定義
type TargetElement = HTMLElement | null;
type HeaderElement = HTMLElement | null;

// ターゲット要素とヘッダー要素を取得
const target: TargetElement = document.querySelector('.p-mv');
const header: HeaderElement = document.querySelector('.l-header');

// オプションを設定
const options: IntersectionObserverInit = {
  root: null, // ビューポートをルートとして使用
  rootMargin: '0px',
  threshold: 0.1, // 10%の交差を閾値とする
};

// IntersectionObserverを作成
const observer: IntersectionObserver = new IntersectionObserver(
  (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (!entry.isIntersecting) {
        // p-mvが90%以上見えなくなったら
        if (header) header.classList.add('is-main');
      } else {
        // p-mvが10%以上見えるようになったら
        if (header) header.classList.remove('is-main');
      }
    });
  },
  options
);

// ターゲット要素の監視を開始
if (target) observer.observe(target);
